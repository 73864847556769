import React, { Component } from 'react';

class Footer extends Component {
	render() {

		if(this.props.data) {
			var networks= this.props.data.social.map(function(network){
				return (
					<li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
				);
			})
			var email = this.props.data.email;
			var emailLink = "mailto:" + email;
		}

		return (
			<footer>
				<div className="row">
					<div className="twelve columns">
						<ul className="social-links">
							{networks}
						</ul>

						<ul className="copyright">
							<li><a title="Email" href={emailLink}>{email}</a></li>
							<li><a title="React Resume Template" href="https://github.com/tbakerx/react-resume-template">Original Template</a> &copy; Copyright 2017 Tim Baker</li>
							<li>Design by <a title="Styleshout" href="http://www.styleshout.com/">Styleshout</a></li>
						</ul>

					</div>
					<div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
				</div>
			</footer>
		);
	}
}

export default Footer;
